import type React from 'react';

export default function InputWrapper({
  type,
  placeholder,
  name,
  value,
  required = false,
  defaultValue,
  disabled = false,
  onChange,
  readOnly = false,
  className,
  startAdornment,
  endAdornment,
}: {
  type: string;
  placeholder?: string;
  name: string;
  value?: string;
  required?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
  readOnly?: boolean;
  className?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}): React.ReactNode {
  return (
    <div className="relative flex items-center justify-center mb-1">
      {startAdornment ? <div className="absolute left-3">{startAdornment}</div> : null}
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        required={required}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={onChange}
        readOnly={readOnly}
        className={`${className} ${startAdornment ? 'pl-10' : ''} ${endAdornment ? 'pr-10' : ''}`}
      />
      {endAdornment ? <div className="absolute right-3">{endAdornment}</div> : null}
    </div>
  );
}
