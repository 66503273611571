import { type ReactElement, useEffect, useState } from 'react';
import { formatPhoneNumber } from 'apps/caredash/app/utils/formatter';
import InputWrapper from '~/components/InputWrapper';
import { LucidePhone, LucideSmartphone } from 'lucide-react';

export default function PhoneInput({
  className = '',
  name,
  value = '',
  placeholder = '',
  onChange = e => {},
  readOnly = false,
  startAdornment = false,
  disabled = false,
}: {
  className?: string;
  name: string;
  value?: string;
  placeholder?: string;
  onChange?: (e: any) => void;
  readOnly?: boolean;
  startAdornment?: boolean;
  disabled?: boolean;
}): ReactElement {
  const [phone, setPhone] = useState(value ? formatPhoneNumber(value) : '');

  const startAdornmentIcon =
    name === 'homePhone' ? <LucidePhone className="h-4 w-4" /> : <LucideSmartphone className="w-4 h-4" />;

  const changeHandler = (e): void => {
    setPhone(formatPhoneNumber(e.target.value as string));
    if (onChange) {
      onChange(e);
    }
  };

  useEffect(() => {
    setPhone(formatPhoneNumber(value));
  }, [value]);

  return (
    <InputWrapper
      type="tel"
      className={className}
      name={name}
      value={String(phone)}
      placeholder={placeholder}
      onChange={changeHandler}
      readOnly={readOnly}
      startAdornment={startAdornment ? startAdornmentIcon : null}
      disabled={disabled}
    ></InputWrapper>
  );
}
